import React, { FormEvent, useEffect, useState } from 'react'
import { LoaderFunctionArgs, useLoaderData, Form, ActionFunctionArgs } from 'react-router-dom'
import { Tenant, TenantUpdate, Throughputs, loadTenant, updateTenant } from '../api/tenants'
import styled from 'styled-components'
import CapInput from '../components/capInput'
import pricingTiers from '../config/tiers.json'

type TierConfig = {
  anchor: number
  accessPoliciesCap: number
  appRegistrationCap: number
  assetsMerkleLogCap: number
  assetsPublicCap: number
  assetsSimpleHashCap: number
  blobsCap: number
  blobsSizeCap: number
  compliancePoliciesCap: number
  enterpriseSSOCap: number
  eventsCap: number
  eventsPerMonthCap: number
  invitesCap: number
  locationsCap: number
  tenantUsersCap: number
  throughput: string
  expired: boolean
}

export type TenantData = {
  tenant: Tenant
}

const caps = [
  'AccessPolicies',
  'AppRegistrations',
  'AssetsMerkleLog',
  'AssetsPublic',
  'AssetsSimpleHash',
  'Blobs',
  'BlobsSize',
  'CompliancePolicies',
  'EnterpriseSSO',
  'Events',
  'EventsPerMonth',
  'Invites',
  'Locations',
  'TenantUsers',
]

const Presets = styled.div`
  margin: 1px;

  button {
    width: 134px;
  }
`

const Grid = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 250px 320px 1fr;

  button {
    grid-column: 2/3;
  }

  label {
    grid-column: 1/2;
    padding-right: 24px;
  }

  input {
    grid-column: 2/3;
    margin-right: 20px;
    width: 300px;
  }

  select {
    grid-column: 2/3;
    width: 300px;
    margin: 0px 20px 0px 5px;
  }

  .group-label {
    grid-column: 1/2;
  }

  input.radio {
    width: auto;
  }

  button {
    width: 300px;
  }
`

export const tenantLoader = async (args: LoaderFunctionArgs): Promise<TenantData | null> => {
  if (args.params.tenantId) {
    return loadTenantData(args.params.tenantId)
  }
  return null
}

const loadTenantData = async (tenantId: string): Promise<TenantData> => {
  const tenant = await loadTenant(tenantId)
  return { tenant }
}

export const updateTenantAction = async ({ request, params }: ActionFunctionArgs) => {
  if (request.method === 'POST') {
    const formData = await request.formData()
    const data: TenantUpdate = { caps: {} }
    const anchorInterval = formData.get('anchor_interval')
    if (anchorInterval !== null) {
      data.anchor_interval = Number(anchorInterval.toString())
    }
    for (const cap of caps) {
      const capValue = formData.get(cap)
      if (capValue !== null && data.caps !== undefined) {
        data.caps[cap] = Number(capValue.toString())
      }
    }

    const throughput = formData.get('throughput')?.toString()
    data.throughput = Throughputs.LOW
    if (throughput === Throughputs.HIGH) {
      data.throughput = Throughputs.HIGH
    }
    if (throughput === Throughputs.READONLY) {
      data.throughput = Throughputs.READONLY
    }

    const verifiedDomain = formData.get('verified_domain')?.toString()
    if (verifiedDomain !== undefined) {
      data.verified_domain = verifiedDomain
    }

    const expired = formData.get('expired')?.toString().toLowerCase()
    // checkbox form data is undefined if not checked
    data.expired = false
    if (expired !== undefined) {
      data.expired = expired === 'true'
    }

    return await updateTenant(`tenant/${params.tenantId}`, data)
  }
  return null
}

const TenantDetailsElement: React.FC = () => {
  const data = useLoaderData() as TenantData | null
  const [anchor, setAnchor] = useState<number>(0)
  const [accessPoliciesCap, setAccessPoliciesCap] = useState<number>(0)
  const [appRegistrationsCap, setAppRegistrationCap] = useState<number>(0)
  const [assetsMerkleLogCap, setAssetsMerkleLogCap] = useState<number>(0)
  const [assetsPublicCap, setAssetsPublicCap] = useState<number>(0)
  const [assetsSimpleHashCap, setAssetsSimpleHashCap] = useState<number>(0)
  const [blobsCap, setBlobsCap] = useState<number>(0)
  const [blobsSizeCap, setBlobsSizeCap] = useState<number>(0)
  const [compliancePoliciesCap, setCompliancePoliciesCap] = useState<number>(0)
  const [enterpriseSSOCap, setEnterpriseSSOCap] = useState<number>(0)
  const [eventsCap, setEventsCap] = useState<number>(0)
  const [eventsPerMonthCap, setEventsPerMonthCap] = useState<number>(0)
  const [invitesCap, setInvitesCap] = useState<number>(0)
  const [locationsCap, setLocationsCap] = useState<number>(0)
  const [tenantUsersCap, setTenantUsersCap] = useState<number>(0)
  const [expired, setExpired] = useState<boolean>(false)
  const [throughput, setThroughput] = useState<Throughputs>(Throughputs.LOW)
  const [verifiedDomain, setVerifiedDomain] = useState<string>('')
  const [updating, setUpdating] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    if (data !== null) {
      setAnchor(data.tenant.anchor_interval | 0)
      setAccessPoliciesCap(data.tenant.caps.AccessPolicies | 0)
      setAppRegistrationCap(data.tenant.caps.AppRegistrations | 0)
      setAssetsMerkleLogCap(data.tenant.caps.AssetsMerkleLog | 0)
      setAssetsPublicCap(data.tenant.caps.AssetsPublic | 0)
      setAssetsSimpleHashCap(data.tenant.caps.AssetsSimpleHash | 0)
      setBlobsCap(data.tenant.caps.Blobs | 0)
      setBlobsSizeCap(data.tenant.caps.BlobsSize | 0)
      setCompliancePoliciesCap(data.tenant.caps.CompliancePolicies | 0)
      setEnterpriseSSOCap(data.tenant.caps.EnterpriseSSO | 0)
      setEventsCap(data.tenant.caps.Events | 0)
      setEventsPerMonthCap(data.tenant.caps.EventsPerMonth | 0)
      setInvitesCap(data.tenant.caps.Invites | 0)
      setLocationsCap(data.tenant.caps.Locations | 0)
      setTenantUsersCap(data.tenant.caps.TenantUsers | 0)
      setThroughput(data.tenant.throughput)
      setExpired(data.tenant.expired)
      setVerifiedDomain(data.tenant.verified_domain ? data.tenant.verified_domain : '')
      setLoading(false)
    }
    setUpdating(false)
  }, [data])

  const setPricingTier = (tierConfig: TierConfig) => {
    setAnchor(tierConfig.anchor)
    setAccessPoliciesCap(tierConfig.accessPoliciesCap)
    setAppRegistrationCap(tierConfig.appRegistrationCap)
    setAssetsMerkleLogCap(tierConfig.assetsMerkleLogCap)
    setAssetsPublicCap(tierConfig.assetsPublicCap)
    setAssetsSimpleHashCap(tierConfig.assetsSimpleHashCap)
    setBlobsCap(tierConfig.blobsCap)
    setBlobsSizeCap(tierConfig.blobsSizeCap)
    setCompliancePoliciesCap(tierConfig.compliancePoliciesCap)
    setEnterpriseSSOCap(tierConfig.enterpriseSSOCap)
    setEventsCap(tierConfig.eventsCap)
    setEventsPerMonthCap(tierConfig.eventsPerMonthCap)
    setInvitesCap(tierConfig.invitesCap)
    setLocationsCap(tierConfig.locationsCap)
    setTenantUsersCap(tierConfig.tenantUsersCap)
    setThroughput(tierConfig.throughput as Throughputs)
    setExpired(tierConfig.expired)
  }

  const validateDomain = () => {
    if (enterpriseSSOCap !== 0 && !verifiedDomain) {
      return false
    }

    if (
      !!verifiedDomain &&
      !verifiedDomain.match(
        // ESLint thinks that escaping the period is unnecessary. We don't want to match all chars.
        //eslint-disable-next-line
        '^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}.)*(xn--)?([a-z0-9][a-z0-9-]{0,60}|[a-z0-9-]{1,30}.[a-z]{2,})$'
      )
    ) {
      return false
    }

    return true
  }

  const validate = (e: FormEvent<HTMLFormElement>): boolean => {
    if (validateDomain()) {
      setUpdating(true)
      return true
    }

    e.preventDefault()
    return false
  }

  return (
    <>
      {!loading && data !== null ? (
        <>
          <div>{`Tenant Identity: ${data.tenant.identity}`}</div>

          <Presets>
            <button
              onClick={() => {
                setPricingTier(pricingTiers.free)
              }}
            >
              Free
            </button>
            <button
              data-test="expired-button"
              onClick={() => {
                setPricingTier(pricingTiers.expired)
              }}
            >
              Expired
            </button>
            <button
              onClick={() => {
                setPricingTier(pricingTiers.dev)
              }}
            >
              Dev
            </button>
            <button
              data-test="readonly-button"
              onClick={() => {
                setPricingTier(pricingTiers.readonly)
              }}
            >
              ReadOnly
            </button>
            <button
              onClick={() => {
                setPricingTier(pricingTiers.enterprise)
              }}
            >
              Enterprise
            </button>
          </Presets>

          <Form method="post" onSubmit={validate}>
            <Grid>
              <button type="submit">Save</button>
              <div className={updating ? 'spin' : ''}></div>
              <label htmlFor="domain">verified domain :</label>
              <input
                id="domain"
                type="text"
                name="verified_domain"
                required={enterpriseSSOCap !== 0}
                className={validateDomain() ? '' : 'bad'}
                value={verifiedDomain}
                formNoValidate={true}
                onChange={(e) => setVerifiedDomain(e.target.value)}
              />
              {!!(!verifiedDomain && enterpriseSSOCap) && (
                <span data-test="bad-domain" className="badge bad">
                  verified domain is required if ESSO is enabled
                </span>
              )}
              <label htmlFor="anchor">anchor interval :</label>
              <select
                id="anchor"
                name="anchor_interval"
                value={anchor}
                onChange={(e) => setAnchor(Number(e.target.value))}
              >
                <option value={12 * 60 * 60}>12 h</option>
                <option value={24 * 60 * 60}>1 day</option>
                <option value={2 * 24 * 60 * 60}>2 days</option>
                <option value={7 * 24 * 60 * 60}>7 days</option>
                <option value={30 * 24 * 60 * 60}>30 days</option>
              </select>

              <CapInput
                id="locationsCap"
                name="Locations"
                min={-1}
                value={locationsCap}
                originalValue={data.tenant.caps.Locations}
                text="locationsCap :"
                setter={setLocationsCap}
              />

              <CapInput
                id="invitesCap"
                name="Invites"
                min={-1}
                value={invitesCap}
                originalValue={data.tenant.caps.Invites}
                text="invitesCap :"
                setter={setInvitesCap}
              />

              <CapInput
                id="enterpriseSSOCap"
                name="EnterpriseSSO"
                min={0}
                max={1}
                value={enterpriseSSOCap}
                originalValue={data.tenant.caps.EnterpriseSSO}
                text="enterpriseSSOCap :"
                setter={setEnterpriseSSOCap}
              />

              <CapInput
                id="compliancePoliciesCap"
                name="CompliancePolicies"
                min={-1}
                value={compliancePoliciesCap}
                originalValue={data.tenant.caps.CompliancePolicies}
                text="compliancePoliciesCap :"
                setter={setCompliancePoliciesCap}
              />

              <CapInput
                id="blobsSizeCap"
                name="BlobsSize"
                min={-1}
                value={blobsSizeCap}
                originalValue={data.tenant.caps.BlobsSize}
                text="blobsSizeCap :"
                setter={setBlobsSizeCap}
              />

              <CapInput
                id="blobsCap"
                name="Blobs"
                min={-1}
                value={blobsCap}
                originalValue={data.tenant.caps.Blobs}
                text="blobsCap :"
                setter={setBlobsCap}
              />

              <CapInput
                id="assetsMerkleLogCap"
                name="AssetsMerkleLog"
                min={-1}
                value={assetsMerkleLogCap}
                originalValue={data.tenant.caps.AssetsMerkleLog}
                text="assetsMerkleLogCap :"
                setter={setAssetsMerkleLogCap}
              />

              <CapInput
                id="assetsSimpleHashCap"
                name="AssetsSimpleHash"
                min={-1}
                value={assetsSimpleHashCap}
                originalValue={data.tenant.caps.AssetsSimpleHash}
                text="assetsSimpleHashCap :"
                setter={setAssetsSimpleHashCap}
              />

              <CapInput
                id="assetsPublicCap"
                name="AssetsPublic"
                min={-1}
                value={assetsPublicCap}
                originalValue={data.tenant.caps.AssetsPublic}
                text="assetsPublicCap :"
                setter={setAssetsPublicCap}
              />

              <CapInput
                id="eventsCap"
                name="Events"
                min={-1}
                value={eventsCap}
                originalValue={data.tenant.caps.Events}
                text="eventsCap :"
                setter={setEventsCap}
              />

              <CapInput
                id="eventsPerMonthCap"
                name="EventsPerMonth"
                min={-1}
                value={eventsPerMonthCap}
                originalValue={data.tenant.caps.EventsPerMonth}
                text="eventsPerMonthCap :"
                setter={setEventsPerMonthCap}
              />

              <CapInput
                id="appRegistrationsCap"
                name="AppRegistrations"
                min={-1}
                value={appRegistrationsCap}
                originalValue={data.tenant.caps.AppRegistrations}
                text="appRegistrationsCap :"
                setter={setAppRegistrationCap}
              />

              <CapInput
                id="accessPoliciesCap"
                name="AccessPolicies"
                min={-1}
                value={accessPoliciesCap}
                originalValue={data.tenant.caps.AccessPolicies}
                text="accessPoliciesCap :"
                setter={setAccessPoliciesCap}
              />

              <CapInput
                id="tenantUsersCap"
                name="TenantUsers"
                min={-1}
                value={tenantUsersCap}
                originalValue={data.tenant.caps.TenantUsers}
                text="tenantUsersCap :"
                setter={setTenantUsersCap}
              />

              <label htmlFor="expired">Free Trial Expired :</label>
              <input
                id="expired"
                type="checkbox"
                name="expired"
                value="true"
                checked={expired}
                onChange={(e) => setExpired(e.target.value === 'true')}
              />

              <div className="group-label">
                <span style={{ marginRight: '10px' }}>{`throughput: `}</span>
              </div>
              <div>
                <label htmlFor="throughput-low">LOW</label>
                <input
                  id="throughput-low"
                  className="radio"
                  type="radio"
                  name="throughput"
                  value="LOW"
                  checked={throughput === Throughputs.LOW}
                  onChange={() => setThroughput(Throughputs.LOW)}
                ></input>
                <label htmlFor="throughput-readonly">READONLY</label>
                <input
                  id="throughput-readonly"
                  className="radio"
                  type="radio"
                  name="throughput"
                  value="READONLY"
                  checked={throughput === Throughputs.READONLY}
                  onChange={() => setThroughput(Throughputs.READONLY)}
                ></input>
                <label htmlFor="throughput-high">HIGH</label>
                <input
                  id="throughput-high"
                  className="radio"
                  type="radio"
                  name="throughput"
                  value="HIGH"
                  checked={throughput === Throughputs.HIGH}
                  onChange={() => setThroughput(Throughputs.HIGH)}
                ></input>
              </div>
            </Grid>

            <div></div>
          </Form>
        </>
      ) : (
        <div className="spin" data-test="spin"></div>
      )}
    </>
  )
}

const TenantDetails = TenantDetailsElement

export default TenantDetails
