import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import ErrorPage from './pages/error'
import TenantDetails, { tenantLoader, updateTenantAction } from './pages/tenantDetails'
import { ThemeProvider } from 'styled-components'

import { lightTheme } from './themes'
import { GlobalStyles } from './styles'

const initialiseApp = async () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'tenant/:tenantId',
          element: <TenantDetails />,
          errorElement: <ErrorPage />,
          loader: tenantLoader,
          action: updateTenantAction,
        },
      ],
    },
    {
      path: '/logged-out',
      element: (
        <div className="loggedOut">
          <h1>DataTrails Management</h1>
          You are logged out please <a href="/login">Login</a>
        </div>
      ),
    },
  ])

  const container = document.getElementById('app')
  const root = createRoot(container as Element)

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <RouterProvider router={router}></RouterProvider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

initialiseApp()
