import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface Whoami {
  sub: string
  iss: string
  email: string
}

type Props = {
  children: React.JSX.Element[] | React.JSX.Element
}

export let CSRFToken = ''

type UserContextType = {
  whoAmI: Whoami | undefined
}

const UserContext = createContext<UserContextType>({
  whoAmI: undefined,
})

const UserContextProvider = (props: Props) => {
  const [whoAmI, setWhoAmI] = useState<Whoami>()
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    fetch('/session/archivist/internal/v1/whoami')
      .then((response) => {
        if (!response.ok) {
          throw { message: response.statusText, status: response.status }
        }
        CSRFToken = response.headers.get('X-Csrf-Token') || ''
        response.json().then((data: Whoami) => {
          setWhoAmI(data)
        })
      })
      .catch((error) => {
        if (error?.status === 401 || error?.status === 403) {
          navigate('/logged-out')
          return
        }
        throw error
      })
      .finally(() => setLoading(false))
  }

  return (
    <UserContext.Provider value={{ whoAmI }}>
      {loading ? (
        <div className="loggedOut spin"></div>
      ) : (
        <>{'length' in props.children && props.children?.length ? { ...props.children } : props.children}</>
      )}
    </UserContext.Provider>
  )
}

export const useUserInfo = () => useContext(UserContext)

export { UserContext, UserContextProvider }
