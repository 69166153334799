import React from 'react'
import { UserContextProvider } from './contexts/loginContext'
import Root from './pages/root'

const MainApp: React.FC = () => {
  return (
    <UserContextProvider>
      <Root></Root>
    </UserContextProvider>
  )
}

const App = MainApp

export default App
