import React, { Dispatch, SetStateAction } from 'react'

type CapInputProps = {
  id: string
  name: string
  text: string
  min: number
  max?: number
  value: number
  originalValue: number
  setter: Dispatch<SetStateAction<number>>
}

const CapInput: React.FC<CapInputProps> = (props) => {
  return (
    <>
      <label htmlFor={props.id}>{props.text}</label>
      <input
        id={props.id}
        min={props.min}
        max={props.max}
        type="number"
        name={props.name}
        value={props.value}
        onChange={(e) => props.setter(Number(e.target.value))}
      />
      {showLoweringWarning(props.value, props.originalValue)}
    </>
  )
}

// show a warning badge if the cap has been lowered from original value
const showLoweringWarning = (newVal: number, current: number) => {
  return (
    isLowered(newVal, current) && (
      <span className="badge bad">lowered from: {current === -1 ? '\u221e' : current} </span>
    )
  )
}

// check if the new value is lower - special case for -1 meaning infinite cap
const isLowered = (value: number, original: number) => {
  if (value !== -1 && (value < original || original === -1)) {
    return true
  }
  return false
}

export default CapInput
