import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useLocation, Link, Outlet } from 'react-router-dom'
import { useUserInfo } from '../contexts/loginContext'
import styled from 'styled-components'

const Container = styled.div`
  width: 600px;

  input {
    width: 240px;
  }

  button {
    width: 300px;
  }
`

const TopBar = styled.div`
  margin-bottom: 50px;
  span,
  a {
    float: right;
  }
`

const stripTenantPrefix = (tenantId: string) => {
  if (tenantId.startsWith('/tenant/')) {
    return tenantId.substring(8)
  }

  if (tenantId.startsWith('tenant/')) {
    return tenantId.substring(7)
  }

  return tenantId
}

export const RootElement: React.FC = () => {
  const user = useUserInfo()
  const navigate = useNavigate()
  const location = useLocation()
  const [tenantId, setTenantId] = useState<string>('')
  const [badTenant, setBadTenant] = useState<boolean>(false)

  useEffect(() => {
    if (location.pathname.startsWith('/tenant/')) {
      setTenantId(stripTenantPrefix(location.pathname))
    }
  }, [])

  const changeTenant = (ev: ChangeEvent<HTMLInputElement>) => {
    setTenantId(stripTenantPrefix(ev.target.value))
    if (ev.target.value) {
      setBadTenant(false)
      return
    }
    setBadTenant(true)
  }

  const goToTenant = () => {
    if (tenantId) {
      return navigate(`/tenant/${tenantId}`)
    }

    setBadTenant(true)
  }

  return (
    <>
      <TopBar>
        <Link reloadDocument={true} to="/logout">
          Logout
        </Link>
        <span>{user.whoAmI?.email}</span>
      </TopBar>
      <Container>
        <input
          type="text"
          name="tenantId"
          className={badTenant ? 'bad' : ''}
          value={tenantId}
          onChange={changeTenant}
          placeholder="tenant id here"
        ></input>
        <button onClick={goToTenant}>Load</button>
      </Container>
      <div>
        <Outlet></Outlet>
      </div>
    </>
  )
}

const Root = RootElement

export default Root
