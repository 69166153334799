import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className="loggedOut">
      Something went wrong please reload the page or navigate back to
      <Link to="/" reloadDocument={true}>
        home page
      </Link>
    </div>
  )
}

export default ErrorPage
