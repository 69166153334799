export const lightTheme = {
  common: {
    aligment: 'center',
    font_family: 'Roboto, Helvetica, sans-serif',
    font_size: '1.125rem',
    line_height: '1.125rem',
    color: '#0f1828',
    font_weight: '500',
    letter_spacing: '0.025ch',
  },

  button: {
    background: 'linear-gradient(rgb(6, 169, 232), rgb(0, 137, 200))',
    padding: '8px 20px',
    border: 'none',
    color: '#ffffff',
    text_transform: 'uppercase',
  },
}
